import cardinalLogo from './teamLogos/Cardinal Commando Character.jpg'
import navyGotBackLogo from './teamLogos/navyGotBackLogo.jpg'
import whiteLogo from './teamLogos/sugar daddies.jpg'
import blackLogo from './teamLogos/black sheep.jpg'
import forestLogo from './teamLogos/green intbagger.jpg'


const NavyCardinalSummary =['Boy, did we have ourselves a barnburner between "Navy Got Back" and the "Cardinal Commandos"!',
    'The "Navy Got Back" crew started off hotter than a Times Square pretzel cart, marching down the field like they were late for shore leave. Ryan "Black Magic" Black conjured up the first touchdown, but the extra point attempt? Fuhgeddaboutit! Batted away like a pesky mosquito at a summer barbecue.',
    'The Cardinal Commandos\' first drive was shakier than a cafeteria Jell-O, nearly ending on fourth down. But wait! Daniel "Catch Me If You Can" Breezy snagged a tipped ball, giving his team more lives than a cat with a lottery ticket. He followed it up with another grab that was smoother than butter on a bald monkey.',
    'But hold onto your headsets, folks! Adam "Pick-Six" Cole of Navy Got Back said, "You\'ve got mail!" and delivered an interception straight to the house! However, their two-point attempt went about as well as trying to parallel park an aircraft carrier, thanks to Ashtyn "The Swat Team" Paschall.',
    'After halftime, the Cardinal Commandos came out guns blazing like they\'d chugged a gallon of rocket fuel. Luis "Q-Tips" Quintana juked and jived, Daniel Breezy was stickier than flypaper, and Armando "The Catcher in the Rye" Martinez kept the chains moving. Then, faster than you can say "Holy Toledo!" Quintana found himself in the end zone, catching a pass so pretty it could win a beauty pageant.',
    'But Navy Got Back wasn\'t about to let this ship sink! They nickel-and-dimed their way down the field with more precision than a Swiss watch factory. Mike "The Other" Breezy and Adam "Pick-Six" Cole kept the drive alive, and then - BAM! - Mike Breezy caught the touchdown dagger, leaving the Commandos\' hopes more deflated than a Patriots\' football.',
    'In the end, Navy Got Back sailed away with the "W," proving they\'ve got more than just backside - they\'ve got game! And that\'s all she wrote, folks. This is Miss Zerman saying, "He... could... go... all... the... way... home," because that\'s exactly where I\'m headed! Goodnight, everybody!'
  ]

  const WhiteCardinalSummary =['We\'ve got a juicy recap of the flag football showdown between the Sugar Daddies and the Cardinal Commandos that\'ll have you more excited than finding a parking spot on Ocean Drive!',
    'The Sugar Daddies, fresh off a victory sweeter than key lime pie, started the game with a drive that shaved over 14 minutes off of the clock. Rookie Terrell "Lightning" Littlejohn was diving for balls like a spring breaker after their last brain cell, keeping the drive alive. It all ended with Eric "The Baker" Behrmann serving up a touchdown pass to Matthew "The Catcher" Eichhorn - a connection smoother than their shared Cuban coffee in the morning, if you know what I mean (well neither do I, I\'m just a bot!)',
    'The Cardinal Commandos, desperate to avoid back-to-back losses like tourists avoid I-95 during rush hour, tightened up their D faster than you can say "South Beach diet." Their defense held the Daddies to just 1 point for the rest of the game - talk about a sugar crash!',
    'The Commandos\' offense started hotter than a drag queen\'s wig glue on Pride Day, with Adrian "The Aquaman" Waters, Luis "Q-Tips" Quintana, and Daniel "Passes Wind" Breezy catching passes. But Breezy, with just one man to beat, fell faster than property values during hurricane season!',
    'Just when you thought things couldn\'t get wilder, Willy "The Palm Beach Express" Brown, who commutes further than a snowbird fleeing winter, snatched a gift-wrapped interception.',
    'After halftime, the Commandos\' offense was as flat as the Everglades, turning the ball over on a quick 4 and out. But the Sugar Daddies\' red zone threat fizzled out after 4 straight incompletions, including a knockdown by Michael Navarra.',
    'Joey "The Sack Grabber" Carr brought more pressure than a Wynwood gallery owner on Art Basel weekend, setting up another Brown interception. This time, he took it to the house and Behrmann ran it in for an extra point to make in 13-0.',
    'But hold onto your stone crabs, folks! The Commandos weren\'t done! Rob "The Rocket" Bramande found Breezy for a TD smoother than a mojito at Ball & Chain. With the score at 13-7 and time ticking down, the tension was higher than a Tyreek Hill traffic stop!',
    'Breezy, standing tall on his formerly wobbly legs, snagged an interception that had Commandos fans more hopeful than a Marlins fan in April. But alas, their comeback fell shorter than my patience in Fort Lauderdale traffic.',
    'In the end, the Sugar Daddies walked away with their second W of the day, proving they\'re sweeter than the whipped cream under my bed.'
  ]
  

  const noRecapAvailable = ['Recap Not Available']

  const weeklyGameResults = {1 :[{homeTeamName: 'Navy Got Back', awayTeamName: 'Sugar Daddies', homeTeamLogo : navyGotBackLogo, awayTeamLogo:whiteLogo,
     homeTeamScore:8, awayTeamScore:15,homeTeamRecord:'(1 - 1)', awayTeamRecord:'(1 - 0)', noRecap: true, recapText:noRecapAvailable, status:"Final"  },
    {homeTeamName: 'Cardinal Commandos', awayTeamName: 'Navy Got Back', homeTeamLogo : cardinalLogo, awayTeamLogo:navyGotBackLogo,
     homeTeamScore:6, awayTeamScore:20,homeTeamRecord:'(0 - 1)', awayTeamRecord:'(1 - 1)', noRecap: false, recapText: NavyCardinalSummary, recapTitle:
     "Rear Admirals: Navy Got Back's Fabulous Flotilla Sinks Cardinal Hopes", status:"Final"  },
    {homeTeamName: 'Green IN\'Teabaggers', awayTeamName: 'Black Sheep', homeTeamLogo : forestLogo, awayTeamLogo:blackLogo,
     homeTeamScore:0, awayTeamScore:14,homeTeamRecord:'(0 - 1)', awayTeamRecord:'(1 - 0)', noRecap: true, recapText:noRecapAvailable, status:"Final"  }]
,
    2 :[{homeTeamName: 'Sugar Daddies', awayTeamName: 'Green IN\'Teabaggers', homeTeamLogo : whiteLogo, awayTeamLogo:forestLogo,
      homeTeamScore:34, awayTeamScore:13,homeTeamRecord:'(3 - 0)', awayTeamRecord:'(0 - 2)', noRecap: true, recapText:noRecapAvailable, status:"Final"  },
     {homeTeamName: 'Sugar Daddies', awayTeamName: 'Cardinal Commandos', homeTeamLogo : whiteLogo, awayTeamLogo:cardinalLogo,
      homeTeamScore:13, awayTeamScore:7,homeTeamRecord:'(3 - 0)', awayTeamRecord:'(0 - 2)', 
      noRecap: false, recapText: WhiteCardinalSummary, recapTitle:'Pride and Pigskin: Sugar Daddies Serve Cardinal Commandos a Bitter Treat', status:"Final"  },
     {homeTeamName: 'Black Sheep', awayTeamName: 'Navy Got Back', homeTeamLogo : blackLogo, awayTeamLogo:navyGotBackLogo,
      homeTeamScore:14, awayTeamScore:0,homeTeamRecord:'(2 - 0)', awayTeamRecord:'(1 - 2)', noRecap: true, recapText:noRecapAvailable, status:"Final"  }],

      3 :[{homeTeamName: 'Green IN\'Teabaggers', awayTeamName: 'Cardinal Commandos', homeTeamLogo : forestLogo, awayTeamLogo:cardinalLogo,
        homeTeamScore:0, awayTeamScore:0,homeTeamRecord:'(0 - 2)', awayTeamRecord:'(0 - 2)', noRecap: true, recapText:noRecapAvailable, status:"10:30am"  },
       {homeTeamName: 'Green IN\'Teabaggers', awayTeamName: 'Navy Got Back', homeTeamLogo : forestLogo, awayTeamLogo:navyGotBackLogo,
        homeTeamScore:0, awayTeamScore:0,homeTeamRecord:'(0 - 2)', awayTeamRecord:'(1 - 2)', noRecap: true, recapText: noRecapAvailable, status:"11:30am"  },
       {homeTeamName: 'Sugar Daddies', awayTeamName: 'Black Sheep', homeTeamLogo : whiteLogo, awayTeamLogo:blackLogo,
        homeTeamScore:0, awayTeamScore:0,homeTeamRecord:'(3 - 0)', awayTeamRecord:'(2 - 0)', noRecap: true, recapText:noRecapAvailable, status:"12:30pm"  }],

        4 :[{homeTeamName: 'Black Sheep', awayTeamName: 'Cardinal Commandos', homeTeamLogo : blackLogo, awayTeamLogo:cardinalLogo,
          homeTeamScore:0, awayTeamScore:0,homeTeamRecord:'(2 - 0)', awayTeamRecord:'(0 - 2)', noRecap: true, recapText:noRecapAvailable, status:"10:30am"  },
         {homeTeamName: 'Black Sheep', awayTeamName: 'Green IN\'Teabaggers', homeTeamLogo : blackLogo, awayTeamLogo:forestLogo,
          homeTeamScore:0, awayTeamScore:0,homeTeamRecord:'(2 - 0)', awayTeamRecord:'(0 - 2)', noRecap: true, recapText: noRecapAvailable, status:"11:30am"  },
         {homeTeamName: 'Navy Got Back', awayTeamName: 'Sugar Daddies', homeTeamLogo : navyGotBackLogo, awayTeamLogo:whiteLogo,
          homeTeamScore:0, awayTeamScore:0,homeTeamRecord:'(1 - 2)', awayTeamRecord:'(3 - 0)', noRecap: true, recapText:noRecapAvailable, status:"12:30pm"  }],

          5 :[{homeTeamName: 'Cardinal Commandos', awayTeamName: 'Black Sheep', homeTeamLogo : cardinalLogo, awayTeamLogo:blackLogo,
            homeTeamScore:0, awayTeamScore:0,homeTeamRecord:'(0 - 2)', awayTeamRecord:'(2 - 0)', noRecap: true, recapText:noRecapAvailable, status:"10:30am"  },
           {homeTeamName: 'Sugar Daddies', awayTeamName: 'Cardinal Commandos', homeTeamLogo : whiteLogo, awayTeamLogo:cardinalLogo,
            homeTeamScore:0, awayTeamScore:0,homeTeamRecord:'(3 - 0)', awayTeamRecord:'(0 - 2)', noRecap: true, recapText: noRecapAvailable, status:"11:30am"  },
           {homeTeamName: 'Navy Got Back', awayTeamName: 'Green IN\'Teabaggers', homeTeamLogo : navyGotBackLogo, awayTeamLogo:forestLogo,
            homeTeamScore:0, awayTeamScore:0,homeTeamRecord:'(1 - 2)', awayTeamRecord:'(0 - 2)', noRecap: true, recapText:noRecapAvailable, status:"12:30pm"  }]

  }


    export default weeklyGameResults;