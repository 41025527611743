import './App.css';
import SiteHeader from './SiteHeader.js';
import WeekList from './WeekList';
import GameSummary from './GameSummary';
import weeklyGameResults from './GameSummaryData.js';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSwipeable } from 'react-swipeable';

function App() {

  const [selectedWeek, setSelectedWeek] = useState(2);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
    if(eventData.dir === "Right")
      {
        setSelectedWeek(Math.max(1, selectedWeek - 1));
      }
      if(eventData.dir === "Left")
        {
          setSelectedWeek(Math.min(5, selectedWeek + 1));
        }
    }
});

  let gameSummaries =  weeklyGameResults[selectedWeek].map((game, index) => {
    return <GameSummary  key = {index} {...game} isMobile={isMobile}/>});
  

  const onWeekClick = (weekNo) => {
    setSelectedWeek(weekNo);
  }



  return (

<div className='h-full'>
<SiteHeader isMobile={isMobile}/>
    <div className={'bg-gray-200 block  min-h-full ' + (isMobile ? "pt-20 " : "pt-40 ")}>
        <WeekList isMobile={isMobile} onWeekClick={onWeekClick} selectedWeek={ selectedWeek } weeks={[{id: 1, date:'SEP 14'},{id: 2, date:'SEP 21'},{id: 3, date:'SEP 28'},{id: 4, date:'OCT 5'},{id: 5, date:'OCT 12'}]} />
        <div {...handlers} className='pt-4'>
         {gameSummaries}
        </div>
  </div>
</div>
  );
}

export default App;
