function TeamScoreMobile({teamColor, teamLogo, teamName, teamRecord, teamScore})
{
 return     (          
                <div className={"grid grid-cols-[15%_50%_15%_15%] w-full h-16 items-center " + teamColor}>
                    <div><img className="size-12 ml-[4px]" src={teamLogo} alt ="The team logo"></img></div>
                    <div className="font-bold text-lg font-roboto">{teamName}</div>
                    <div className="font-bold text-base font-roboto">{teamRecord}</div>                            
                    <div className ="text-xl font-roboto font-bold justify-right text-right">{teamScore}</div>
                </div>   
);
}

export default TeamScoreMobile;