import sffflLogo from './teamLogos/sfffl logo.png'

function SiteHeader({isMobile})
{
return ( 
        <header className={"bg-[#07a0ae]  fixed z-10 min-w-1120 w-full block shadow-xl box-border " + (isMobile ? "h-12 " : "h-20 ")}>
                <h1 className="float-left m-0">
                    <div className="inline-block relative z-50">
                        <img alt ="The SFFFL Logo" src ={sffflLogo} className={"inline-block relative " + (isMobile ? "w-24" : "max-w-60")} />
                    </div>
                </h1>
            <h1 className={"text-white font-bold text-3xl text-center font-roboto drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] " + (isMobile ? "py-2 mr-[36px] text-xl" : "py-10 mr-48 text-3xl")}>Fall 2024 Game Results</h1>
        </header>
);
}

export default SiteHeader;