import WeekButton  from "./WeekButton";

function WeekList({weeks, onWeekClick, selectedWeek, isMobile})
{
    const renderedWeeks = weeks.map((week) => {
        return <WeekButton key = {week.id} weekNumber = {week.id} weekDate = {week.date} onWeekClick={onWeekClick} selectedWeek={selectedWeek} isMobile={isMobile}/>
    });

    return <div className={'flex justify-center items-center p-0 flex auto bg-white rounded m-auto  ' + (isMobile ? " w-[370px]" : " w-[750px]")}>{renderedWeeks}</div>
}

export default WeekList;