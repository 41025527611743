import { useState } from 'react';
import TeamScoreMobile from './TeamScoreMobile.js';

function GameSummary({homeTeamName, homeTeamRecord, homeTeamLogo, homeTeamScore, awayTeamName, awayTeamRecord, awayTeamLogo, awayTeamScore, status, recapText, recapTitle, noRecap, isMobile})
{
    const [viewRestState, setViewRestState] = useState(false);
    const awayColor = awayTeamScore >= homeTeamScore ? "" : "text-[#6c6d6f]";
    const homeColor = homeTeamScore >= awayTeamScore ? "" : "text-[#6c6d6f]";

    const onReadMoreClick = () => {
        setViewRestState(viewRestState === true ? false : true);
      };
    const paragraphedRecapPreviewDesktop = recapText.slice(0, 1).map((paragraph, x) => {

        return <p key = {x} className="py-3 font-georgia">{paragraph}</p>}
        );

    const paragraphedRecapRestDesktop = recapText.slice(1, recapText.length).map((paragraph, x) => {
        return <p  key = {x} className="py-3 font-georgia">{paragraph}</p>}
        );

    const titleDiv = <div className="font-bold my-4 text-lg">{recapTitle}</div> 
    const bylineDiv = <div className="font-bold">By Miss 'Zoomer' Zerman</div>  
    const paragraphedRecapMobile =  recapText.map((paragraph, x) => {

        return <p key = {x} className="py-3 font-georgia">{paragraph}</p>}
        );

    const recapSectionDesktop = noRecap ?  <div>Recap Not Available</div> : 
     <div className="w-2/3 justify-left items-left flex flex-col pt-2">
    <div className="font-bold">By Miss 'Zoomer' Zerman</div>
 
    {paragraphedRecapPreviewDesktop}
    <div className={"transition-[opacity,display] transition-discrete duration-500 ease-in-out starting:opacity-0  " + (viewRestState === true ? "opacity-100 " : "hidden opacity-0 " )}>{paragraphedRecapRestDesktop}</div>
    <p className="cursor-pointer text-blue-700" onClick={onReadMoreClick} >{viewRestState === true ? "Collapse" : "Read More..."}</p>
    </div>



 if(isMobile)
{
    return <div className={" bg-white w-[370px]  m-auto rounded mt-2  "}>
                <div className={"grid grid-rows-[50%_50%] h-[125px]"}>
                        <div>
                            <TeamScoreMobile teamColor = {awayColor} teamLogo = {awayTeamLogo} teamName = {awayTeamName} teamRecord = {awayTeamRecord} teamScore ={awayTeamScore}/>
                        </div>
                        <div>
                            <TeamScoreMobile teamColor = {homeColor} teamLogo = {homeTeamLogo} teamName = {homeTeamName} teamRecord = {homeTeamRecord} teamScore ={homeTeamScore}/>
                        </div>
                    </div>
                    <div>
                        <div className='flex flex-col justify-center items-center mb-auto w-[95%] ml-2'>
                        <div className={"transition-[opacity,display] transition-discrete duration-500 ease-in-out starting:opacity-0  " + (viewRestState === true ? "opacity-100 " : "hidden opacity-0 " )}>{titleDiv}{bylineDiv}{paragraphedRecapMobile}</div>  
                        <p  onClick={onReadMoreClick} className={noRecap ? "'" : "text-blue-700"}> {status !== "Final" ? status : noRecap ? "Recap Not Available" : viewRestState ? "Close" : "Recap"} </p> 
                        </div>

                </div> 
          </div>
}
else{

    return <div className={" bg-white w-[750px] m-auto rounded my-2 py-6 "}>
            <div className="grid grid-cols-[47.5%_5%_47.5%] w-full h-20">
                <div className={"grid grid-cols-[65%_18%_12%] w-full h-20 " + awayColor}>
                        <div className='flex flex-col justify-center items-center mb-auto'>
                            <div className="font-bold text-xl px-2 font-roboto">{awayTeamName} </div>
                            <div className="font-bold text-base px-2 font-roboto">{awayTeamRecord}</div>
                        </div>
                            <img className="size-12" src={awayTeamLogo} alt="away team logo"></img>
                        <div className ="text-4xl font-roboto font-bold justify-right text-right">{awayTeamScore}</div>
                </div>
                <div className ="font-roboto">{status}</div>
                <div className={"grid grid-cols-[17%_21%_63%] w-full h-20 "  + homeColor}>
                    <div className ="text-4xl font-roboto font-bold ml-auto">{homeTeamScore}</div>
                    <img className="size-12 ml-auto" src={homeTeamLogo} alt="home team logo"></img>
                    <div className='flex flex-col justify-center items-center p-0 mb-auto'>
                        <div className="font-bold text-xl px-2 font-roboto">{homeTeamName}</div>
                        <div className="font-bold text-base px-2 font-roboto">{homeTeamRecord}</div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col justify-center items-center mb-auto mt-6'>
                <div className='font-bold text-xl w-2/3 justify-center inline-block'>{recapTitle}</div>
                {recapSectionDesktop}
            </div>
        </div> 

}

}

export default GameSummary;